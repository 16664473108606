<template>
  <v-form>
    <v-text-field
      :value="login.username"
      label="Username"
      outlined />
    <v-text-field
      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
      :type="show2 ? 'text' : 'password'"
      :value="login.password"
      label="Password"
      outlined />
    <v-btn outlined
           class="btnLogin">
      LOGIN
    </v-btn>
  </v-form>
</template>

<script>
export default {
  name: 'FrmLogin',
  props: {
    login: {
      type: Object,
      required: true
    }
  }
};
</script>

<style>
</style>
